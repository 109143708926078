<template lang="pug">
a(v-bind:href="href" v-bind:class="{ active: isActive }" v-on:click="go")
    slot
</template>

<script>
    // import routes from '../common/Routes'

    export default {
        props: {
            href: {
                type: String,
                required: true
            }
        },
        computed: {
            isActive() {
                return this.href === this.$root.currentRoute;
            }
        },
        methods: {
            go(event) {
                event.preventDefault();
                this.$root.currentRoute = this.href;
                window.history.pushState(null, this.href, this.href);
            }
        }
    }
</script>

<style scoped>
    .active {
        color: cornflowerblue;
    }
</style>