<template lang="pug">
    div
        section.panel(v-if="firstRun")
            header.panel-heading.text-center Синхронизация подписчиков
            ul.list-group
                li.list-group-item(v-bind:class="{active: step > 1, bg: step === 1}")
                    .media
                        span.pull-left.thumb-small.m-t-mini
                            i.fa.fa-user.fa-lg.text-default
                        #todo-1.pull-right.text-primary.m-t-small(v-bind:class="{active: step > 1}")
                            i.fa.fa-circle.fa-lg.text.text-default
                            i.fa.fa-check.fa-lg.text-active.text-white
                        .media-body
                            wizard-step-animation(stepTitle="Создаём аккаунт" v-bind:inProgress="step === 1")
                            small.text-muted Подождите некоторое время, пока мы вас регистируем в платформе
                li.list-group-item(v-bind:class="{active: step > 2, bg: step === 2}")
                    .media
                        span.pull-left.thumb-small.m-t-mini
                            i.fa.fa-refresh.fa-lg.text-default
                        #todo-2.pull-right.text-primary.m-t-small(v-bind:class="{active: step > 2}")
                            i.fa.fa-circle.fa-lg.text.text-default
                            i.fa.fa-check.fa-lg.text-active.text-white
                        .media-body
                            wizard-step-animation(stepTitle="Синхронизируем подписчиков" v-bind:inProgress="step === 2")
                            small.text-muted Переносим существующие имейлы в ваш аккаунт
                li.list-group-item(v-bind:class="{active: step > 3, bg: step === 3}")
                    .media
                        span.pull-left.thumb-small.m-t-mini
                            i.fa.fa-coffee.fa-lg.text-default
                        #todo-3.pull-right.text-primary.m-t-small(v-bind:class="{active: step > 2}")
                            i.fa.fa-circle.fa-lg.text.text-default
                            i.fa.fa-check.fa-lg.text-active.text-white
                        .media-body
                            wizard-step-animation(stepTitle="Coffee time" v-bind:inProgress="step === 3")
                            small.text-muted И ещё немножко, сейчас обновим страницу
        .panel-group.m-b#accordion(v-if="!firstRun")
            .panel
                .panel-heading
                    a.accordion-toggle(
                        href="#collapse1" data-toggle="collapse" data-parent="#accordion" aria-expanded="true"
                    ) Синхронизация подписчиков
                .panel-collapse.collapse.in#collapse1(aria-expanded="true")
                    .panel-body
                        h3.m-t Последняя синхронизация
                        h4 {{ new Date(lastImport.updated_since || lastImport.updated_at).toLocaleString() }}
                        p
                            a.btn.btn-info.m-r(
                                href="#" v-bind:class="{disabled: lastImport.state === 1}"
                                v-on:click.prevent="newSync"
                            ) Синхронизировать подписчиков
                            a(
                                v-bind:href="`https://mailganer.com/auth/login/auto/${this.$root.user_info.autologin}?goto_url=https://mailganer.com/app/emails/`"
                                target="_blank"
                            ) Перейти в личный кабинет&nbsp;
                                i.fa.fa-external-link
                        .progress.progress-small.progress-striped.active(v-if="lastImport.state === 1")
                            .progress-bar.progress-bar-info(v-bind:style="'width:' + progress() + '%'")
            .panel(v-if="sites.length > 0")
                .panel-heading
                    a.accordion-toggle.collapsed(
                        href="#collapse2" data-toggle="collapse" data-parent="#accordion" aria-expanded="false"
                    ) Настройки синхронизации
                .panel-collapse.collapse#collapse2(aria-expanded="false")
                    .panel-body
                        label.control-label Список, в который импортировать подписчиков
                        select.form-control(name="account" v-model="selectedSite"
                            v-bind:disabled="settingsSaveInProgress")
                            option(v-for="site in sites" v-bind:value="site.id") {{site.domain}}
                            option(v-bind:value=-1) Создать новый
                        input.form-control.m-t(type="text" name="newList" v-model="newSiteName"
                            v-if="selectedSite === -1"
                            placeholder="Введите название нового списка" v-bind:disabled="settingsSaveInProgress")
                        button.btn.btn-primary.m-t(type="button" v-on:click.prevent="saveSettings"
                            v-bind:disabled="settingsSaveInProgress") Сохранить
            .panel
                .panel-heading
                    a.accordion-toggle.collapsed(
                        href="#id_collapse_recommendations"
                        data-toggle="collapse" data-parent="#accordion" aria-expanded="false"
                    ) Рекомендации
                .panel-collapse.collapse#id_collapse_recommendations(aria-expanded="false")
                    .panel-body
                        div.text-center
                            p
                                a.btn.btn-primary.btn-block(
                                    v-bind:href="`https://mailganer.com/auth/login/auto/${this.$root.user_info.autologin}?goto_url=https://mailganer.com/app/settings/${currentSite.id}/recommend/`"
                                    target="_blank"
                                ) Перейти к настройкам
                            a(
                                href="https://mailganer.com/ru/explanation/integraciya-s-insales-i-podklyuchenie-tovarnyh-rekomendacij" target="_blank"
                            ) Документация по настройке&nbsp;
                                i.fa.fa-external-link
</template>

<script>
    import axios from "axios";
    import Store from "../common/Store";
    import MGApi from "../common/MGApi";
    import WizardStepAnimation from "./WizardStepAnimation";

    const STEP_MIN_TIMEOUT = 2000;
    const STEP_SLEEP_IN_TIMEOUT = STEP_MIN_TIMEOUT / 5;

    export default {
        name: "Wizard_1.1",
        components: {WizardStepAnimation},
        comments:[WizardStepAnimation],
        data: () => ({
            firstRun: true,
            step: 1,
            lastImport: null,
            currentSite: null,
            sites: [],
            selectedSite: null,
            newSiteName: null,
            settingsSaveInProgress: false,
            progress() {
                if (this.lastImport) {
                    if (this.lastImport.state === 2) {
                        return 100;
                    }
                    if (this.lastImport.total > 0) {
                        return this.lastImport.imported / this.lastImport.total * 100;
                    }
                }
                return 0;
            }
        }),
        async mounted() {
            window.console.debug('Mounted Wizard 1.1');
            // Подгружаем информацию о клиенте и магазине
            await Store.getUserInfo();
            await Store.getShopInfo();
            // Проверяем первый ли импорт у пользователя
            try {
                const {data} = await axios.get('/app/imports/', {params: {limit: 1}});
                window.console.debug(data);
                if (data.count > 0) {
                    this.firstRun = false;
                    this.lastImport = data.results[0];
                    if (data.count === 1 && this.progress() < 100) {
                        this.firstRun = true;
                        this.currentSite = {id: this.lastImport.site_id};
                        this.selectedSite = this.currentSite.id;
                        this.step = 2;
                    }
                }
            } catch (e) {
                this.$root.isApiError = true;
                throw new Error(e);
            }
            Store.showApp();
            if (this.firstRun) {
                if (this.step === 1) {
                    await this.step1();
                }
                this.step2();
            } else {
                this.currentSite = this.currentSite || {id: this.lastImport.site_id};
                this.selectedSite = this.currentSite.id;
                this.importProcess(this.currentSite.id);
            }
            MGApi.sites_list().then(({results}) => (this.sites = results));
        },
        methods: {
            async step1() {
                let startStep = performance.now();
                window.console.debug('Получение или создание списка подписчиков');
                this.currentSite = await this.getOrCreateSite();
                this.selectedSite = this.currentSite.id;
                await this.stepAwait(startStep);
                this.step++;
            },
            step2() {
                window.console.debug('Запуск импорта для списка', this.currentSite.id);
                this.importProcess(this.currentSite.id);
            },
            async step3() {
                let startStep = performance.now();
                window.console.debug('Ждем окончания 3-го шага');
                await this.stepAwait(startStep);
                this.step++;
                this.firstRun = false;
            },
            async stepAwait(startStep) {
                while (performance.now() - startStep < STEP_MIN_TIMEOUT) {
                    window.console.debug(performance.now() - startStep);
                    await new Promise(r => setTimeout(r, STEP_SLEEP_IN_TIMEOUT));
                }
            },
            async getOrCreateSite(domain) {
                // Функция создания нового списка для рассылок
                try {
                    // Проверяем на существование списка
                    const {count, results} = await MGApi.sites_list({
                        user: this.$root.user_info.id,
                        domain: domain || this.$root.shop_info.shop
                    });
                    window.console.debug(count, results);
                    if (count > 0) {
                        return results[0];
                    } else {
                        // Создаем новый список
                        const data = await MGApi.sites_create(
                            domain || this.$root.shop_info.shop,
                            this.$root.shop_info.shop,
                            `https://${this.$root.shop_info.shop}`,
                            this.$root.user_info.id
                        );
                        window.console.debug(data);
                        return data;
                    }
                } catch (e) {
                    this.$root.isApiError = true;
                    new Error(e);
                }
            },
            importProcess(site_id, new_sync = false) {
                const id = `f${(~~(Math.random() * 1e8)).toString(16)}`;
                window.console.debug(id, 'START Проверка статуса импорта для site_id:', site_id);
                axios.get('/app/imports/current/', {params: {site_id, new_sync}}).then(({data}) => {
                    window.console.debug(id, data);
                    this.lastImport = data;
                    if (this.progress() < 100) {
                        setTimeout(() => {
                            this.importProcess(site_id);
                        }, 10000); // Проверять каждые 10 секунд
                    } else {
                        this.step++;
                    }
                }).catch(reason => {
                    this.$root.isApiError = true;
                    throw new Error(reason);
                });
                window.console.debug(id, 'END Проверка статуса импорта для site_id:', site_id);
            },
            newSync() {
                this.importProcess(this.currentSite.id, true);
            },
            saveSettings() {
                if (this.selectedSite === -1) {
                    if (this.newSiteName) {
                        window.console.debug('Create new site');
                        this.settingsSaveInProgress = true;
                        MGApi.sites_list({domain: this.newSiteName}).then(({count, results}) => {
                            window.console.debug(count, results);
                            if (count > 0) {
                                this.currentSite = results[0];
                                this.selectedSite = this.currentSite.id;
                                this.settingsSaveInProgress = false;
                                window.$('a[href="#collapse1"]').trigger('click');
                            } else {
                                MGApi.sites_create(
                                    this.newSiteName,
                                    this.$root.shop_info.shop,
                                    `https://${this.$root.shop_info.shop}`,
                                    this.$root.user_info.id
                                ).then(siteData => {
                                    this.currentSite = siteData;
                                    this.selectedSite = this.currentSite.id;
                                    MGApi.sites_list().then(({results}) => (this.sites = results));
                                    this.settingsSaveInProgress = false;
                                    window.$('a[href="#collapse1"]').trigger('click');
                                });
                            }
                        });
                    } else {
                        this.selectedSite = this.currentSite.id;
                    }
                } else {
                    this.currentSite = {id: this.selectedSite};
                }
            }
        },
        watch: {
            async step(newVal) {
                if (newVal === 3) {
                    await this.step3();
                }
            }
        }
    }
</script>

<style scoped>

</style>