import axios from "axios";
import VueCookie from "vue-cookie";
import Store from "./Store";

axios.interceptors.request.use(config => {
    config.headers = {
        ...{
            'Authorization': `CodeRequest ${VueCookie.get('code_request')}`,
            'Access-Control-Allow-Origin': '*'
        }
    };
    return config;
});

export default {
    api_url: '/api/v2',
    auth(username, password) {
        return axios.post(`${this.api_url}/auth/`, {username, password});
    },
    async users_info(uid) {
        try {
            const {data} = await axios.get(`${this.api_url}/users/${uid}/`);
            return data;
        } catch (e) {
            Store.state.isApiError = true;
            throw new Error(e);
        }
    },
    async sites_create(name, domain, url, uid) {
        try {
            const {data} = await axios.post(`${this.api_url}/sites/`, {
                domain: name, domain_ext: domain, url: url, source_type: 1, user: uid
            });
            return data;
        } catch (e) {
            Store.state.isApiError = true;
            throw new Error(e);
        }
    },
    async sites_list(params) {
        try {
            const {data} = await axios.get(`${this.api_url}/sites/`, {params});
            return data;
        } catch (e) {
            Store.state.isApiError = true;
            throw new Error(e);
        }
    },
    async sites_info(uid) {
        try {
            const {data} = axios.get(`${this.api_url}/sites/${uid}/`);
            return data;
        } catch (e) {
            Store.state.isApiError = true;
            throw new Error(e);
        }
    }
};