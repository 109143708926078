import VueCookie from "vue-cookie";
import axios from "axios";
import MGApi from "./MGApi";

const initial_state = {
    currentRoute: window.location.pathname,
    isAuthenticated: () => VueCookie.get('code_request'),
    user_info: null,
    shop_info: null,
    site_info: null,
    isApiError: null,
};

export default {
    state: Object.assign({}, initial_state),
    async getShopInfo() {
        if (!this.state.shop_info) {
            try {
                const {data} = await axios.get('/app/shops/current/');
                this.state.shop_info = data;
            } catch (e) {
                throw new Error(e);
            }
        }
        return this.state.shop_info;
    },
    async getUserInfo() {
        if (!this.state.user_info) {
            this.state.user_info = await MGApi.users_info(VueCookie.get('mg_uid'));
        }
        return this.state.user_info;
    },
    showApp() {
        window.$('#id-app-loading').hide();
        window.$('#app').fadeIn(500);
    },
    hideApp() {
        window.$('#app').fadeOut(500);
        window.$('#id-app-loading').show();
    },
    async logout() {
        this.hideApp();
        await new Promise(r => setTimeout(r, 1000));
        axios.get('/app/logout/', {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then(response => {
            window.console.debug('Logout response:', response);
            this.state = Object.assign(
                this.state,
                {...initial_state, isAuthenticated: () => VueCookie.get('code_request')}
            );
            this.showApp();
        });
    }
};