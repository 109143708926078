<template lang="pug">
    div
        a.h5(href="#") {{ stepTitle }}
            span(v-if="inProgress" v-html="`&nbsp;${'.'.repeat(points)}`") &nbsp;...
</template>

<script>
    export default {
        props: ['stepTitle', 'inProgress'],
        data: () => ({
            points: 1
        }),
        mounted() {
            setInterval(() => {
                if (this.inProgress) {
                    this.points = this.points < 3 ? this.points + 1 : 0;
                }
            }, 250);
        }
    }
</script>

<style scoped>

</style>