<template lang="pug">
MainLayout
    section#content
        .main.padder
            .row
                .col-lg-4.col-lg-offset-4.m-t-large(v-if="!this.isApiError")
                    Authentication(v-if="!this.isAuthenticated()")
                    Wizard(v-if="this.isAuthenticated()")
                .col-lg-3.alert.alert-warning.alert-block.m-t-large
                    h4 Полезные материалы
                    p Статья &nbsp;
                        a(href="https://mailganer.com/ru/explanation/integraciya-s-insales-i-podklyuchenie-tovarnyh-rekomendacij" target="_blank") Интеграция Mailganer и InSales
                    p Запись вебинара&nbsp;
                        a(href="https://www.youtube.com/watch?v=bzZr_8itI_U&amp;t=339s" target="_blank") Эффективные email-коммуникации
            .row
                .col-lg-4.col-lg-offset-4.m-t-large(v-if="this.isApiError")
                    .alert.alert-danger(v-if="this.isApiError")
                        h1 Ошибка сервера
                        hr
                        p Мы работаем над устранением проблемы!
</template>

<script>
    import MainLayout from '../layouts/Main.vue';
    import Store from "../common/Store";
    import VLink from "../components/VLink";
    import Wizard from "../components/Wizard_1.1";
    import Authentication from "../components/Authentication";

    export default {
        name: 'Home',
        data: () => Store.state,
        components: {
            Authentication,
            Wizard,
            VLink,
            MainLayout
        }
    };
</script>

<style scoped>
</style>
