<template lang="pug">
div#app
    <!-- header -->
    header.navbar#header
        ul.nav.navbar-nav.navbar-avatar.pull-right(v-if="this.$root.isAuthenticated()")
            li.dropdown(v-if="this.$root.user_info")
                a.dropdown-toggle(href="#" data-toggle="dropdown")
                    span.hidden-xs-only {{ this.$root.user_info.fullname }}
                    span.thumb-small.avatar.inline
                        img.img-circle(
                            v-bind:src="this.$root.user_info.avatar" v-bind:alt="this.$root.user_info.fullname"
                        )
                    b.caret.hidden-xs-only
                ul.dropdown-menu.pull-right
                    li
                        a(:href="`https://mailganer.com/auth/login/auto/${this.$root.user_info.autologin}`" target="_blank") В личный кабинет
                    li.divider
                    li
                        a(href="#" v-on:click.prevent="loguot")
                            i.fa.fa-sign-out
                            | &nbsp;Выход
        .logo
            a(href="#" target="_blank")
                img(src="https://mailganer.com/static/pages/img/mailganer-logo-color.png"
                    alt="mailganer.com" width="155")
    <!-- / header -->
    slot
    a.support-button(
        :href="`//mailganer.com/auth/login/auto/${$root.user_info.autologin}?next=/app/dashboard/#support-request`"
        target="_blank"
        v-if="$root.user_info"
    )
        i.fa.fa-question-circle
        | &nbsp;&nbsp;Поддержка
</template>

<script>
    import VLink from '../components/VLink.vue';
    import Store from "../common/Store";

    export default {
        components: {
            VLink
        },
        methods: {
            loguot() {
                Store.logout();
            }
        }
    };
</script>

<style scoped lang="scss">
    #headerLogoLink {
        max-width: 159px;

        img {
            width: 100%;
        }
    }

    .support-button {
        z-index: 1030;
        position: fixed;
        bottom: 15px;
        right: 20px;

        background: #78A300;
        color: #ffffff;
        fill: #ffffff;

        padding: 13px 25px;
        font-size: 16px;
        border: none;
        border-radius: 999rem;
        font-weight: 700;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif;
    }
</style>