import Vue from 'vue';
import VueCookie from 'vue-cookie';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Store from "./common/Store";
import NotFound from './pages/404';
import routes from "./common/Routes";
import "./assets/scss/custom.scss";

Sentry.init({
    dsn: process.env.SENTRY_DSN, integrations: [new Integrations.Vue({Vue, attachProps: true})]
});

Vue.use(VueCookie);
Vue.config.productionTip = false;

new Vue({
    el: '#app',
    data: Store.state,
    computed: {
        ViewComponent() {
            return routes[this.currentRoute] || NotFound;
        }
    },
    render(h) {
        return h(this.ViewComponent);
    },
    watch: {
        user_info() {
            window.console.debug('Change user_info:', this.user_info);
            if (window.zE) {
                window.zE(() => {
                    let user = {};
                    if (this.user_info != null) {
                        user = {name: this.user_info.fullname, email: this.user_info.email};
                    }
                    window.zE.identify(user);
                });
            }
        }
    }
});

window.addEventListener('popstate', () => {
    window.console.debug('Handle go to:', this.href);
    Store.state.currentRoute = window.location.pathname
});
