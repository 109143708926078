<template lang="pug">
    section.panel
        header.panel-heading.text-center(v-text="state === 'singin' ? 'Войти' : 'Создать аккаунт'")
        form.panel-body#sing-in(v-if="state === 'singin'" name="singin")
            .block
                label.control-label(for="id_email") Логин
                input#id_email.form-control(
                    type="email" v-model="email" required :disabled="loading" autocomplete="email"
                )
            .block
                label.control-label(for="id_password") Пароль
                input#id_password.form-control(
                    type="password" v-model="password" required :disabled="loading"
                    autocomplete="password"
                )
            button.btn.btn-info(v-on:click="singIn" :disabled="loading") Войти
            .alert.alert-warning.alert-block.m-t(v-if="singInError")
                p Неверный имейл или пароль
            .line.line-dashed
            p.text-muted.text-center
                small Нет аккаунта?
            a.btn.btn-white.btn-block(
                href="#" v-on:click.prevent="() => (state = 'singup')" :disabled="loading"
            ) Создать аккаунт
        form.panel-body#sing-up(v-if="state === 'singup'" name="singup")
            .block
                label.control-label(for="id_name") Имя
                input#id_name.form-control(
                    type="text" v-model="registration.first_name" required :disabled="loading"
                )
            .block
                label.control-label(for="id_email") Email
                input#id_email.form-control(
                    type="email" v-model="registration.email"
                    aria-describedby="emailHelp" required :disabled="loading"
                )
                small#emailHelp.form-text.text-muted.hidden
                    | Данный Email будет использоваться при входе в&nbsp;
                    a(href="//mailganer.com") Mailganer.com
                    | &nbsp;для управления рассылками.
            .block
                label.control-label(for="id_phone") Телефон
                input#id_phone.form-control(
                    type="text" v-model="registration.phone"
                    aria-describedby="phoneHelp" required :disabled="loading"
                )
                small#phoneHelp.form-text.text-muted.hidden Ваш номер телефона для связи менеджера с вами
            button.btn.btn-info(v-on:click="singUp" :disabled="loading") Создать аккаунт
            .alert.alert-warning.alert-block.m-t(v-if="singUpError")
                p Аккаунт на такой имейл уже зарегистрирован. Используйте другой имейл
            .line.line-dashed
            p.text-muted.text-center
                small Уже есть аккаунт?
            a.btn.btn-white.btn-block(
                href="#" v-on:click.prevent="() => (state = 'singin')" :disabled="loading"
            ) Вход
</template>

<script>
    import axios from "axios";
    import Store from "../common/Store";

    export default {
        name: "Authentication",
        data() {
            return {
                loading: false,
                email: null,
                password: null,
                state: 'singup',
                registration: {
                    first_name: null, email: null, phone: null
                },
                singUpError: false,
                singInError: false,
            };
        },
        async mounted() {
            window.console.debug('Mounted Authentication');
            await Store.getShopInfo();
            Store.state.isAuthenticated = () => this.$cookie.get('code_request');
            this.registration.first_name = this.$root.shop_info.name;
            this.registration.email = this.$root.shop_info.email;
            this.registration.phone = this.$root.shop_info.phone;
            Store.showApp();
        },
        methods: {
            singIn(e) {
                if (window.document.forms['singin'].checkValidity()) {
                    e.preventDefault();
                    this.loading = true;
                    this.singInError = false;
                    window.console.debug(this.email, this.password);
                    const formData = new FormData();
                    formData.append('email', this.email);
                    formData.append('password', this.password);
                    axios.post('/app/singin/', formData).then(async response => {
                        window.console.debug(response);
                        Store.hideApp();
                        await Store.getUserInfo();
                        Store.state.isAuthenticated = () => this.$cookie.get('code_request');
                    }).catch(reason => {
                        window.console.debug(reason);
                        this.singInError = true;
                    }).finally(() => {
                        this.loading = false;
                    });
                }
                return false;
            },
            singUp(e) {
                if (window.document.forms['singup'].checkValidity()) {
                    e.preventDefault();
                    this.loading = true;
                    this.singUpError = false;
                    window.console.debug(this.registration);
                    const formData = new FormData();
                    formData.append('first_name', this.registration.first_name);
                    formData.append('email', this.registration.email);
                    formData.append('phone', this.registration.phone);
                    axios.post('/app/singup/', formData).then(async response => {
                        window.console.debug(response);
                        Store.hideApp();
                        await Store.getUserInfo();
                        Store.state.isAuthenticated = () => this.$cookie.get('code_request');
                    }).catch(reason => {
                        window.console.debug(reason);
                        this.singUpError = true;
                    }).finally(() => {
                        this.loading = false;
                    });
                }
                return false;
            }
        }
    };
</script>

<style scoped>

</style>